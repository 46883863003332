<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row">
          <div class="col-md-5">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-md-5 ml-auto">
            <div class="row">
              <div class="col">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <button class="btn btn-sm btn-primary" @click="addItem()">
                  Tambah Item
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <v-container>
    
          <v-col>
              <!-- we use this wrapper to show the second scroll above of table -->
              <div id="wrapper2">
                <div id="div2" class="width-scroll" >
                </div>
              </div>
          </v-col>
            
          <v-col>
          <CDataTable
            class="table-striped"
            :items="computedItems"
            :fields="fields"
            style="max-height: 300px; overflow: auto;"
          >
            <template #image="{ item }">
              <td class="py-2">
                <img :src="item.image" @error="imageLoadOnError" width="80px" />
              </td>
            </template>
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  @click="edit(item)"
                  class="mr-2"
                  color="warning"
                  square
                  size="sm"
                >
                  Edit
                </CButton>
                
                <CButton
                  @click="hapus(item)"
                  color="danger"
                  square
                  size="sm"
                >
                  Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
          <pagination
            v-if="total !== items.length"
            v-model="page"
            :records="total"
            :per-page="50"  
            @paginate="pagination"
          />
          </v-col>
        </v-container>
      </CCardBody>
    </CCard>
    <CModal
      size="lg"
      :title="isUpdate ? 'Edit Item' : 'Tambah Item'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
      :closeOnBackdrop="false"
    >
      <div class="row">
        <div class="col">
          <CInput
            v-model="form.code"
            label="Code Item"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.name"
            label="Nama Item"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.unit"
            label="satuan"
            placeholder="ketik disini"
          />
          <div class="row">
            <div class="col">
              <div class="div">
                <label class="form-label" for="newData.image">Upload Profile Picture</label>
                <input
                  type="file"
                  class="form-control"
                  id="newData.image"
                  @change="selectImage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="createModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah Item
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update Item
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/item"
import firebase from "firebase/compat/app"
import "firebase/auth"
import { uploadImage } from "@/utils/fileUpload";

export default {
  data() {
    return {
      createModal: false,
      fields: data.fields,
      isUpdate: false,
      items: [],
      page: 1,
      total: 0,
      form: {},
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      isSearching: false,
      searchOn: "",
      modalPassword: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: "",
      verificators: [{ value: 0, label: "-" }],
    }
  },
  methods: {
    imageLoadOnError(e) {
          e.target.src = "https://cdn.pixabay.com/photo/2017/02/12/21/29/false-2061131_1280.png"
      },
    selectImage(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.form.image = resp;  
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    getNameVerificator(id) {
      let data = this.verificators.find((item) => {
        return item.value == id
      })
      if (data) {
        return data.label
      } else {
        return "BUKAN VERIFIKATOR"
      }
    },
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },

    searchOff() {
      this.isSearching = false
      this.params.keyword = ""
      this.getData()
    },
    submit() {
      var loading = this.$loading.show()

      this.$store
        .dispatch("item/addItem", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan Item")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          console.error(e)
          this.$toast.success(e)
          loading.hide()
        })
    },
    edit(item) {
      this.form = item
      this.isUpdate = true
      this.createModal = true
    },
    update() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("item/updateItem", { id: this.form.id, data: this.form })
        .then(() => {
          this.$toast.success("Berhasil merubah data item")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("item/deleteItem", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data item")
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("item/getItem", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total
          console.log(this.items)
          console.log('masuk')
          // khusus untuk checkbox
          loading.hide()

        })
        .catch((e) => {
          loading.hide()
        })
    },
    addItem() {
      this.isUpdate = false
      this.form = {}
      this.createModal = true
    },
    changePassword(item) {
      this.modalPassword = true
      this.form.id = item.id
      this.form.full_name = item.full_name
      this.form.firebase_id = item.firebase_id
    },
    inputPassword() {
      this.invalidPassword = ""
      this.isPasswordValid = null
    },
    validatePassword(item) {
      if (item.password.length < 6) {
        this.invalidPassword = "Password kurang dari 6 karakter!!"
        this.isPasswordValid = false
        return false
      } else if (item.password != item.password_confirmation) {
        this.invalidPassword = "Konfirmasi password tidak sama!!"
        this.isPasswordValid = false
        return false
      } else {
        this.invalidPassword = ""
        this.isPasswordValid = null
        return true
      }
    },
    updatePassword() {
      if (this.validatePassword(this.form)) {
        var loading = this.$loading.show()
        this.$store
          .dispatch("item/changePassword", {
            id: this.form.id,
            password: this.form.password,
          })
          .then(() => {
            this.$toast.success("Berhasil merubah password item")
            loading.hide()
            this.modalPassword = false
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      } else {
        this.$toast.error(this.invalidPassword)
      }
    },
    closeModalPassword() {
      this.form = {}
      this.invalidPassword = ""
      this.isPasswordValid = null
      this.modalPassword = false
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },
  },
  computed: {
    computedItems() {
      return this.items.map((item, index) => {
        return {
          ...item,
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
        }
      })
    }
  },
  mounted() {
    this.getData()

    let wrapper_1 = document.querySelector('.table-striped .table-responsive');
    let wrapper_2 = document.querySelector('#wrapper2');
    wrapper_1.onscroll = function() {
      wrapper_2.scrollLeft = wrapper_1.scrollLeft;
    };
      
    wrapper_2.onscroll = function() {
      wrapper_1.scrollLeft = wrapper_2.scrollLeft;
    };
  },
}
</script>

<style>

/* This is the size of the path of the scrollbar */
#wrapper2{
      width: 100%;
      overflow-x: scroll; 
      overflow-y:hidden;
  }

/* This div allow to make the scroll function and show the scrollbar */
  #div2 {
      height: 1px;
      overflow: scroll;
  }

  /* This is the size of the scrollbar */
  .width-scroll{
    width: 1500px;
  }

  @media only screen and (max-width: 767px) {

  /* Adjust styles for mobile display here */
  #wrapper2 {
    width: 100%;
    overflow-x: auto;
  }

  #div2 {
    height: 7px;
    overflow: scroll;
  }

  .width-scroll {
    width: 1500px;
  }

  }

</style>